<template>
  <div class="home-container">
    <div class="home-title-container">
      <div class="home-title initial-transition transition1">Welcome to&nbsp;</div>
      <div class="home-title-name initial-transition transition2">Clew</div>
    </div>
    <p class="home-text initial-transition transition3">Clew is an interactive, mobile Web application.</p>
    <p class="home-text initial-transition transition4">With it, you can discover different sites in the Culture Mile.</p>
    <p class="home-text initial-transition transition5">Move and investigate by clicking the icons - it's easy!</p>
    <p class="home-link-container initial-transition transition6">
      <router-link :to="{ name: 'LocationDetails', params: { slug: 'museum-of-london' } }" class="home-link">Ready to explore?</router-link>
    </p>
  </div>
</template>

<script>
import metaDataMixin from '@/metadata-mixin'
export default {
  name: 'Home',
  mixins: [metaDataMixin],
  created(){
    this.SITE_NAME = process.env.VUE_APP_SITE_NAME
    this.setMetadata(this.SITE_NAME, 'Home');
  }
}
</script>

<style scoped>
.home-container{
  font-family: 'Times New Roman', Times, serif;
  margin: 0 auto;
  max-width:71rem;
  padding:0.5rem;
  width:100%;
}

.home-link-container{
  display: block;
  font-size: 2.25rem;
  opacity:0;
  text-align: center;
}

.home-link{
  color:#fde923;
}

.home-text{
  display: block;
  font-size: 2.25rem;
  text-align: left;
  opacity:0;
}

.home-title{
  display:block;
  font-size: 4rem;
  opacity:0;
}

.home-title-container{
  display:inline-flex;
  justify-content: flex-start;
  width:100%;
}

.home-title-name{
  display:block;
  font-size: 4rem;
  font-style: italic;
  opacity:0;
}

.initial-transition{
	animation: fadeIn ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.transition1{
  animation-delay:2s;
}

.transition2{
  animation-delay:4s;
}

.transition3{
  animation-delay:6s;
}

.transition4{
  animation-delay:8s;
}

.transition5{
  animation-delay:10s;
}

.transition6{
  animation-delay:12s;
}

</style>
